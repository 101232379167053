var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      attrs: { title: _vm.$t("instituicao"), active: _vm.visible },
      on: {
        "update:active": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", [
        _c("p", { staticClass: "w-full text-center mb-4" }, [
          _vm._v(
            _vm._s(
              _vm.$t("selecione-a-instituicao-que-deseja-utilizar-no-momento")
            )
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "grid grid-cols-2 grid-rows-1 gap-6" },
        _vm._l(_vm.institutions(), function (institution) {
          return _c(
            "div",
            {
              key: institution,
              staticClass: "place-self-center cursor-pointer",
              on: {
                mouseover: function ($event) {
                  _vm.hover = institution.id
                },
                mouseleave: function ($event) {
                  _vm.hover = "none"
                },
                click: function ($event) {
                  return _vm.selectedInstitution(institution)
                },
              },
            },
            [
              _c("div", { class: _vm.selectedBackground("student") }, [
                _c(
                  "div",
                  { class: _vm.selectedBorder("student") },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "block mx-6 my-1",
                      staticStyle: { "font-size": "70px" },
                      attrs: { icon: institution.icon },
                    }),
                  ],
                  1
                ),
              ]),
              _c("label", { class: _vm.selectedLabel(institution.id) }, [
                _vm._v(" " + _vm._s(institution.name) + " "),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }