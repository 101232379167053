var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "nav-header py-4",
      on: {
        mouseover: _vm.hovered,
        mouseleave: function ($event) {
          return _vm.hovered(false)
        },
        click: function ($event) {
          _vm.showChildren = !_vm.showChildren
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "header-label flex items-center cursor-pointer relative px-5 py-2",
          class: [
            {
              "text-white bg-primary-gradient header-active":
                _vm.isHeaderActive,
            },
            { "header-open": _vm.isHovered || _vm.showChildren },
          ],
        },
        [
          _c("feather-icon", {
            staticClass: "mr-3",
            attrs: { icon: _vm.header.icon, svgClasses: "h-5 w-5" },
          }),
          _c("span", { staticClass: "whitespace-no-wrap" }, [
            _vm._v(_vm._s(_vm.$t(_vm.header.i18n) || _vm.header.header)),
          ]),
          _c("feather-icon", {
            staticClass: "ml-1",
            attrs: { icon: "ChevronDownIcon", svgClasses: "h-4 w-4" },
          }),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade-top-2x" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showChildren,
                expression: "showChildren",
              },
            ],
            ref: "headerDropdown",
            staticClass:
              "header-children h-nav-menu-dd shadow-drop text-intial absolute shadow-lg py-2",
            class: { "dd-right": _vm.dropRight },
          },
          _vm._l(_vm.header.items, function (item, index) {
            return _c(
              "li",
              { key: item.name },
              [
                !item.submenu
                  ? [
                      _c(
                        "h-nav-menu-item",
                        {
                          attrs: {
                            to: item.slug != "external" ? item.url : "",
                            href: item.slug == "external" ? item.url : "",
                            icon: item.icon,
                            target: item.target,
                            isDisabled: item.isDisabled,
                            slug: item.slug,
                          },
                        },
                        [
                          _c("span", { staticClass: "truncate" }, [
                            _vm._v(_vm._s(_vm.$t(item.i18n) || item.name)),
                          ]),
                          item.tag
                            ? _c(
                                "vs-chip",
                                { attrs: { color: item.tagColor } },
                                [_vm._v(_vm._s(item.tag))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c("h-nav-menu-group", {
                        key: "group-" + index,
                        attrs: {
                          openHover: "",
                          group: item,
                          groupIndex: index,
                          open: _vm.checkGrpChildrenActive(item),
                        },
                      }),
                    ],
              ],
              2
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }