import Rest from '../Rest'

export default class SystemService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1';

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  ping() {
    return this.get('/ping').then(data => {
      return data
    })
  }
}
