<template>
  <vs-popup
    :title="$t('grupo')"
    :active.sync="visible"
  >

    <div>
      <p class="w-full text-center mb-4">{{ $t('selecione-o-grupo-para-ter-acesso-as-funcionalidades') }}</p>
    </div>
    
    <div class="grid grid-cols-2 grid-rows-1 gap-6">

      <div 
        @mouseover="hover = 'student'"
        @mouseleave="hover = 'none'"
        @click="selectedRole('student')"
        :class="personaContainerClasses"
      >
        <div :class="selectedBackground('student')">
        <div :class="selectedBorder('student')">
          <img src="@/assets/images/personas/student.png" :style="personaImgStyle" :class="personaImgClasses">
        </div>
        </div>
        <label :class="selectedLabel('student')"> ALUNO </label>
      </div>

      <div 
        @mouseover="hover = 'teacher'" 
        @mouseleave="hover = 'none'"
        @click="selectedRole('teacher')"
        :class="personaContainerClasses"
      >
        <div :class="selectedBackground('teacher')">
        <div :class="selectedBorder('teacher')"> 
          <img src="@/assets/images/personas/teacher.png" :style="personaImgStyle" :class="personaImgClasses">
        </div>
        </div>
        <label :class="selectedLabel('teacher')"> PROFESSOR </label>
      </div>

    </div>
  </vs-popup>
</template>


<script>
import UserService from '@/services/api/UserService.ts'
import LoginService from '@/services/api/LoginService'
import auth from '@/auth/authService'

export default {

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    service: null,
    hover: 'none',
    closedAutomatically: false
  }),

  watch: {
    visible: function(show) {
      if (!show && !this.closedAutomatically) {
        this.closePopupInState(true)
      }
      this.closedAutomatically = false
    }
  },

  mounted() {
    this.service = UserService.build(this.$vs)
    this.activeProfile = this.$store.getters.activeProfile
  },

  methods: {
    selectedRole(persona) {
      this.$vs.loading()
      this.service.changeRole(persona).then(
        (data) => {
          //this here updates it on acl object!!!
          this.$acl.setPermissions(data.permissions)

          //This here updates it on the local storage!!!
          this.$store.dispatch('updateUserInfo', {permissions: data.permissions, roles: data.roles}).then(() => {
            this.closePopup('success', true)

            if (this.$router.currentRoute.name !== 'home') {
              const currentRoutePermissions = this.$router.currentRoute.meta.rule.split('|')
              let found = false
              const permit = data.permissions.find((permission) => {
                currentRoutePermissions.forEach((routePermission) => {
                  if (permission.name === routePermission) {
                    found = true
                  }
                })
                return found
              })
              if (!permit) this.$router.push('/')
            }
          })
          
        },
        (error) => {
          this.closePopup('error', false)
        }
      )
    },
    
    closePopup(state, cleanFirstAccess) {
      this.closedAutomatically = true
      switch (state) {
      case 'error':
        this.$vs.loading.close()
        this.notifyError(this.$vs, this.$t('nao-foi-possivel-alterar-o-tipo-de-usuario'))
        break
      case 'success':
        this.$vs.loading.close()
        this.notifySuccess(this.$vs, this.$t('seu-tipo-de-usuario-foi-alterado-com-sucesso'))
        break
      default:
        break
      }

      this.closePopupInState(cleanFirstAccess)
      this.visible = false
    },

    closePopupInState(cleanFirstAccess = false) {
      if (cleanFirstAccess && this.$store.getters.firstAccess) {
        this.$store.dispatch('updateUserInfo', {firstAccess: false})
      }
      this.$store.dispatch('common/setRoleModalVisibility', false)
    },
    selectedLabel(persona) {
      const highlight = 'text-primary '
      const defaultClasses = 'text-xl pt-2 block text-center font-medium cursor-pointer'

      let computedStyle = ''    

      if (persona === this.activeProfile || persona === this.hover) {
        computedStyle += highlight
      }

      return computedStyle + defaultClasses
    },
    selectedBorder(persona) {
      const highlight = 'border-primary '
      const defaultColor = 'border-grey '
      const defaultClasses = 'border-solid rounded-lg'
      let computedStyle = ''

      computedStyle += (persona === this.activeProfile || persona === this.hover) ? highlight : defaultColor

      
      return computedStyle + defaultClasses
    },
    selectedBackground(persona) {
      const highlight = 'selectedBackground'

      return this.hover === persona ? highlight : ''
    }
  },

  computed: {
    personaContainerClasses() {
      return 'place-self-center cursor-pointer'
    },
    activeProfile() {
      return this.$store.getters.activeProfile
    },
    personaImgClasses() {
      return 'block mx-6 my-1 '
    },
    personaImgStyle() {
      // This is in 3:4 ratio for good looking size, and the border spacing is dependant of
      // the img margins.
      return `
      height: 200px;
      width: 150px;
      `
    }
  }
}
</script>

<style lang="scss" scoped>
  .selectedBackground {
    background-image: radial-gradient(circle at bottom center, $primary, #0000);
  }
</style>