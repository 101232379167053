<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">
    <div :key="key" class="text-right leading-tight hidden sm:block">
      <p class="font-semibold z- truncate">{{ this.truncate(activeUserInfo.displayName, 20) }}</p>
      <small v-if="showActiveProfile">{{activeProfile}}</small>
      <!-- <div class="flex flex-col items-end" v-if="showActiveProfile">
        <small>{{activeProfile}}</small>
      </div> -->
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <vs-avatar :src="activeUserInfo.profile_picture" :text="avatarText" :key="key"></vs-avatar>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 15rem" >
          <li @click="gotoProfile" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2"> {{$t('profile.x')}}</span>
          </li>

          <li 
            v-if="showActiveProfile && userHasPerson"
            @click="showRoleModal" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="UsersIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2"> {{$t('profile.role')}}</span>
          </li>
          
          <vs-divider class="m-1"/>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2"> {{$t('profile.exit')}}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { isAdmin } from '@/util/Util'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      key: 0
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    userPeople() {
      return this.activeUserInfo.people
    },
    userInstitutions() {
      return this.$store.getters['institution/available']
    },
    activeInstitution() {
      return this.$store.getters['institution/active']
    },
    avatarText() {
      const names = this.activeUserInfo.displayName.split(' ')
      const fisrtName = names[0][0].toUpperCase()
      let secontName = ''
      if (names.length > 1) {
        secontName = names[1][0].toUpperCase()
      }
      return fisrtName + secontName
    },
    activeProfile() {
      return this.$t(this._.upperFirst(this.$store.getters.activeProfile))
    },
    showActiveProfile() {
      const activeInstitution = this.$store.getters['institution/active']

      const hasActiveInstitution = (
        'id' in activeInstitution &&
        activeInstitution.id
      ) || false

      return !hasActiveInstitution || isAdmin()
    },
    userHasPerson() {
      return (
        this.userPeople && this.userPeople.length > 0
      ) || false
    }
  },
  watch: {
    '$store.state.AppActiveUser'(val) {
      this.key++
    },
    activeInstitution() {
      this.key++
    },
  },
  methods: {
    logout() {
      this.updateLogoDomain(this.$store.getters['common/domainConfig'], this.$store)
      this.$store.dispatch('auth/logout', {vs: this.$vs})
    },
    gotoProfile() {
      this.$router.push('/profile')
    },
    showRoleModal() {
      this.$store.dispatch('common/setRoleModalVisibility', true)
    }
  }
}
</script>
