var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeUserInfo.displayName
    ? _c(
        "div",
        { staticClass: "the-navbar__user-meta flex items-center" },
        [
          _c(
            "div",
            {
              key: _vm.key,
              staticClass: "text-right leading-tight hidden sm:block",
            },
            [
              _c("p", { staticClass: "font-semibold z- truncate" }, [
                _vm._v(
                  _vm._s(this.truncate(_vm.activeUserInfo.displayName, 20))
                ),
              ]),
              _vm.showActiveProfile
                ? _c("small", [_vm._v(_vm._s(_vm.activeProfile))])
                : _vm._e(),
            ]
          ),
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
            },
            [
              _c(
                "div",
                { staticClass: "con-img ml-3" },
                [
                  _c("vs-avatar", {
                    key: _vm.key,
                    attrs: {
                      src: _vm.activeUserInfo.profile_picture,
                      text: _vm.avatarText,
                    },
                  }),
                ],
                1
              ),
              _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                _c(
                  "ul",
                  { staticStyle: { "min-width": "15rem" } },
                  [
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: { click: _vm.gotoProfile },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "UserIcon", svgClasses: "w-4 h-4" },
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(" " + _vm._s(_vm.$t("profile.x"))),
                        ]),
                      ],
                      1
                    ),
                    _vm.showActiveProfile && _vm.userHasPerson
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                            on: { click: _vm.showRoleModal },
                          },
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: "UsersIcon",
                                svgClasses: "w-4 h-4",
                              },
                            }),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(" " + _vm._s(_vm.$t("profile.role"))),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("vs-divider", { staticClass: "m-1" }),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: { click: _vm.logout },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" },
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(" " + _vm._s(_vm.$t("profile.exit"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }