<template>
  <vs-popup
    :title="$t('instituicao')"
    :active.sync="visible"
  >

    <!-- <div>
      <p class="w-full text-center mb-4">
        Você pode utilizar a Educatena para seus projetos pessoais ou estando vinculado a uma instituição.
      </p>
    </div> -->

    <div>
      <p class="w-full text-center mb-4">{{ $t('selecione-a-instituicao-que-deseja-utilizar-no-momento') }}</p>
    </div>
    
    <div class="grid grid-cols-2 grid-rows-1 gap-6">

      <div
        v-for="institution in institutions()"
        :key="institution"
        @mouseover="hover = institution.id"
        @mouseleave="hover = 'none'"
        @click="selectedInstitution(institution)"
        class="place-self-center cursor-pointer"
      >
        <div :class="selectedBackground('student')">
        <div :class="selectedBorder('student')">
          <font-awesome-icon :icon="institution.icon" style="font-size: 70px;" class="block mx-6 my-1"/>
        </div>
        </div>
        <label :class="selectedLabel(institution.id)"> {{ institution.name }} </label>
      </div>

    </div>
  </vs-popup>
</template>


<script>
import UserService from '@/services/api/UserService.ts'
import {setActiveInstitution} from '@/util/Institution.ts'

export default {

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    service: null,
    hover: 'none'
  }),

  watch: {
    visible(val) {
      if (val === false) {
        this.$store.dispatch('institution/updateModalVisibility', val)
        if (this.$store.getters.firstAccess) {
          this.$store.dispatch('updateUserInfo', {firstAccess: false})
        }
      }
    }
  },

  mounted() {
    this.service = UserService.build(this.$vs)
    this.activeProfile = this.$store.getters.activeProfile
  },

  methods: {
    selectedInstitution(institution) {
      
      if (institution.id === 0) {
        institution = null
      }

      setActiveInstitution(institution, this.$acl).then(
        (success) => {
          this.notifySuccess(this.$vs, this.$t('agora-voce-esta-logado-na-instituicao-institution-institution-name-pessoal', [institution ? institution.name : this.$t('pessoal')]), 2000, 'center-top')
          this.visible = false
        },
        (error) => {
          console.error(error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-logar-na-instituicao-selecionada'))
        }
      )
      
    },
    selectedLabel(persona) {
      const highlight = 'text-primary '
      const defaultClasses = this.$t('text-xl-pt-2-block-text-center-font-medium-cursor-pointer')

      let computedStyle = ''    

      if (persona === this.activeProfile || persona === this.hover) {
        computedStyle += highlight
      }

      return computedStyle + defaultClasses
    },
    selectedBorder(persona) {
      const highlight = 'border-primary '
      const defaultColor = 'border-grey '
      const defaultClasses = 'border-solid rounded-lg flex items-center justify-center p-2'
      let computedStyle = ''

      computedStyle += (persona === this.activeProfile || persona === this.hover) ? highlight : defaultColor

      
      return computedStyle + defaultClasses
    },
    selectedBackground(persona) {
      const highlight = 'selectedBackground'

      return this.hover === persona ? highlight : ''
    },
    institutions() {
      const institutions = []
      // institutions.push({
      //   id: 0,
      //   type: 'persona',
      //   name: 'Pessoal',
      //   icon: 'chalkboard-teacher'
      // })
      institutions.push(...this.userInstitutions)
      institutions.forEach(institution => {
        if (institution.type !== 'persona') {
          institution.icon = 'university'
        }
      })
      return institutions
    },
  },

  computed: {
    userInstitutions() {
      return this.$store.getters['institution/available']
    },
    activeInstitution() {
      return this.$store.getters['institution/active']
    },
    personaImgClasses() {
      return 'block mx-6 my-1 '
    },
  }
}
</script>

<style lang="scss" scoped>
  .selectedBackground {
    background-image: radial-gradient(circle at bottom center, $primary, #0000);
  }

  .con-vs-popup {
    z-index: 99999999999999;
  }
</style>