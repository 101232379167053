var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "permission",
          rawName: "v-permission:any",
          value: _vm.rule,
          expression: "rule",
          arg: "any",
        },
        {
          name: "permission",
          rawName: "v-permission:unless",
          value: _vm.unlessRule,
          expression: "unlessRule",
          arg: "unless",
        },
      ],
      staticClass: "vs-sidebar--item",
      class: [
        { "vs-sidebar-item-active": _vm.activeLink },
        { "disabled-item pointer-events-none": _vm.isDisabled },
      ],
    },
    [
      _vm.to
        ? _c(
            "router-link",
            {
              class: [{ "router-link-active": _vm.activeLink }],
              attrs: {
                tabindex: "-1",
                exact: "",
                to: _vm.to,
                target: _vm.target,
              },
            },
            [
              !_vm.featherIcon
                ? _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticStyle: {
                          "font-size": "18px",
                          "margin-right": "16px",
                        },
                        attrs: { icon: _vm.icon },
                      }),
                    ],
                    1
                  )
                : _c("feather-icon", {
                    class: { "w-3 h-3": _vm.iconSmall },
                    attrs: { icon: _vm.icon },
                  }),
              _vm._t("default"),
            ],
            2
          )
        : _c(
            "a",
            { attrs: { target: _vm.target, href: _vm.href, tabindex: "-1" } },
            [
              !_vm.featherIcon
                ? _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticStyle: {
                          "font-size": "18px",
                          "margin-right": "16px",
                        },
                        attrs: { icon: _vm.icon },
                      }),
                    ],
                    1
                  )
                : _c("feather-icon", {
                    class: { "w-3 h-3": _vm.iconSmall },
                    attrs: { icon: _vm.icon },
                  }),
              _vm._t("default"),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }