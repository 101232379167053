var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper navbar-full p-0" },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "navbar-custom navbar-skelton",
            class: _vm.navbarClasses,
            style: _vm.navbarStyle,
            attrs: { color: _vm.navbarColor },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "vx-logo cursor-pointer mx-auto flex items-center",
                attrs: { tag: "div", to: "/" },
              },
              [
                _c("logo", {
                  staticClass: "w-10 mr-4 fill-current text-primary",
                }),
              ],
              1
            ),
            _c("i18n"),
            _c("search-bar"),
            _c("profile-drop-down"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }