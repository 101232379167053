var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "the-navbar__user-meta flex items-center" },
    [
      _c(
        "vs-dropdown",
        {
          staticClass: "cursor-pointer",
          attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
        },
        [
          _c(
            "div",
            {
              key: _vm.key,
              staticClass: "text-right leading-tight hidden sm:block pl-4 pr-4",
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-col items-center" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { "font-size": "20px" },
                    attrs: { icon: "university" },
                  }),
                ],
                1
              ),
              _vm.hideActiveProfile
                ? _c("div", { staticClass: "flex-col justify-center" }, [
                    _vm.activeInstitution.id
                      ? _c(
                          "small",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.bottom",
                                value: {
                                  content: _vm.activeInstitution.name,
                                  delay: { show: 100, hide: 500 },
                                },
                                expression:
                                  "{\n            content: activeInstitution.name,\n            delay: { show: 100, hide: 500 },\n          }",
                                modifiers: { bottom: true },
                              },
                            ],
                            staticClass: "w-24 truncate",
                          },
                          [_vm._v(_vm._s(_vm.activeInstitution.name))]
                        )
                      : _vm._e(),
                  ])
                : _c("small", [_vm._v(_vm._s(_vm.$t("pessoal")))]),
            ]
          ),
          _vm.userInstitutions && _vm.userInstitutions.length
            ? _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                _c(
                  "ul",
                  { staticStyle: { "min-width": "15rem" } },
                  [
                    _vm.userInstitutions && _vm.userInstitutions.length > 0
                      ? _c("vs-divider", { staticClass: "m-1" }, [
                          _vm._v(_vm._s(_vm.$t("fields.institution"))),
                        ])
                      : _vm._e(),
                    _vm.isAdmin()
                      ? _c(
                          "li",
                          {
                            class: _vm.institutionHighlightStyle(null),
                            on: {
                              click: function ($event) {
                                return _vm.setCurrentInstitution(null)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "chalkboard-teacher" },
                                }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.$t("pessoal"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._l(_vm.userInstitutions, function (institution) {
                      return _c(
                        "li",
                        {
                          key: institution.id,
                          class: _vm.institutionHighlightStyle(institution.id),
                          on: {
                            click: function ($event) {
                              return _vm.setCurrentInstitution(institution)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "inline-flex items-center" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "university" },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(institution.name)),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _c("vs-dropdown-menu", [
                _c("ul", { staticClass: "p-2" }, [
                  _c("li", { staticClass: "text-sm font-bold text-left" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "voce-nao-esta-vinculado-a-nenhuma-instituicao"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }