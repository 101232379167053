<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon icon="BellIcon" class="cursor-pointer mt-1 mr-2" :badge="unreadNotifications.length" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ unreadNotificationsCount }}</h3>
        <p class="opacity-75">{{ notificationWord }}</p>
      </div>

      <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items">
          <li @click="clickedNotification(notification)" v-for="notification in unreadNotifications" :key="notification.uuid" class="flex justify-between px-4 py-4 notification cursor-pointer">
            <div class="flex items-start">
              <feather-icon :icon="formatIconName(notification.dropdownIcon)" :svgClasses="[`text-${notification.dropdownCategory}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
              <div class="mx-2">
                <span class="font-medium block notification-title" :class="[`text-${notification.dropdownCategory}`]">{{ notification.dropdownTitle }}</span>
                <small class="break-all">{{ notification.message(50) }}</small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(notification.sentAt) }}</small>
          </li>
        </ul>
      </component>

      <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer">
        <span @click="openAllNotifications">{{ $t('ver-mais') }}</span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import NotificationService from '@/services/api/NotificationService'

export default {
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
        service: null
      }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    unreadNotifications () { return this.$store.getters.unreadNotifications },
    unreadNotificationsCount() {
      const len = this.unreadNotifications.length
      return ((len > 0) ?
        ((len > 1) ? this.$t('len-novas', [len]) : this.$t('1-nova')) :
        this.$t('nenhuma')
      )
    },
    notificationWord() {
      const len = this.unreadNotifications.length
      return (len > 1) ? this.$t('notificacoes') : this.$t('notificacao')
    }
  },
  methods: {
    openAllNotifications() {
      this.$router.push('/notifications')
    },
    clickedNotification(notification) {
      notification.onClick(this.service, notification)
    },
    formatIconName(icon) {
      return this.$utils.format.featherIcon.fromNotify(icon)
    },
    elapsedTime (startTime) {
      const x        = new Date(startTime)
      const now      = new Date()
      let timeDiff = now - x
      timeDiff    /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff    = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff    = Math.floor(timeDiff / 60)

      const hours   = Math.round(timeDiff % 24)
      timeDiff    = Math.floor(timeDiff / 24)

      const days    = Math.round(timeDiff % 365)
      timeDiff    = Math.floor(timeDiff / 365)

      const years   = timeDiff

      const yearStr = ` ${this.$t('elapsed-ano')} `
      const yearsStr = ` ${this.$t('elapsed-anos')} `
      const daysStr = ` ${this.$t('elapsed-days')} `
      const dayStr = ` ${this.$t('elapsed-day')} `
      const timeStr = ` ${this.$t('elapsed-hora')} `
      const agoStr = ` ${this.$t('elapsed-ago')} `
      const nowStr = this.$t('elapsed-now')

      if (years > 0) {
        return `${years + (years > 1 ? yearsStr : yearStr)}${agoStr}`
      } else if (days > 0) {
        return `${days + (days > 1 ? daysStr : dayStr)}${agoStr}`
      } else if (hours > 0) {
        return `${hours + (hours > 1 ? ' hrs ' : timeStr)}${agoStr}`
      } else if (minutes > 0) {
        return `${minutes + (minutes > 1 ? ' mins ' : ' min ')}${agoStr}`
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? `s ${agoStr}` : nowStr)
      }

      return this.$t('just-now')
    },
    // Method for creating dummy notification time
    randomDate ({ hr, min, sec }) {
      const date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    },
    openNotificationLink(notification) {
      notification.onClick(this.service)(notification)
    }
  },
  mounted() {
    this.service = NotificationService.build(this)
    if (this.socketEnabled()) {
      const userId = this.$store.getters.userId
      window.Echo.private(`App.Models.User.${userId}`).notification(
        (notification) => {
          this.service.handleIncomingNotification(notification)
        }
      )
    }
  },
  destroyed() {
    if (this.socketEnabled()) {
      const userId = this.$store.getters.userId
      window.Echo.leaveChannel(`private-App.Models.User.${userId}`)
    }
  }
}

</script>

