<template>
  <vs-dropdown v-if="!serverOnline || !internetOnline" vs-custom-content vs-trigger-click class="cursor-pointer flex">
    <div v-if="!internetOnline" class="flex mr-2 items-center p-1 border rounded-lg">
      <div class="relative">
        <feather-icon icon="GlobeIcon" svgClasses="h-6 w-6"/>
        <feather-icon 
          icon="XIcon"
          svgClasses="text-warning stroke-3"
          class="overlay bg-danger border border-warning rounded-full"
        />
      </div>
    </div>

    <div v-if="!serverOnline" class="flex mr-2 items-center p-1 border rounded-lg">
      <div class="relative">
        <feather-icon icon="ServerIcon" svgClasses="h-6 w-6"/>
        <feather-icon
          icon="XIcon"
          svgClasses="text-warning stroke-3"
          class="overlay bg-danger border border-warning rounded-full"
        />
      </div>
    </div>

    <vs-dropdown-menu class="notification-dropdown vx-navbar-dropdown">
      <ul class="py-2 px-4">
        <li class="flex items-center" v-if="!internetOnline">
          <div class="relative">
            <feather-icon icon="GlobeIcon" svgClasses="h-6 w-6"/>
            <feather-icon 
              icon="XIcon"
              svgClasses="text-warning stroke-3"
              class="overlay bg-danger border border-warning rounded-full"
            />
          </div>
          <span class="pl-2"> {{ $t('voce-esta-sem-conexao-com-a-internet') }}</span>
        </li>
        <li class="flex items-center" v-if="!serverOnline">
          <div class="relative">
            <feather-icon icon="ServerIcon" svgClasses="h-6 w-6"/>
            <feather-icon 
              icon="XIcon"
              svgClasses="text-warning stroke-3"
              class="overlay bg-danger border border-warning rounded-full"
            />
          </div>
          <span class="pl-2"> {{ $t('verifique-sua-conexao-pois-nao-foi-possivel-conectar-com-o-servidor') }}</span>
        </li>
      </ul>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import SystemService from '@/services/api/SystemService'

export default {
  components: {
  },
  data () {
    return {
      server: null,
      interval: null,
      intervalRunning: false
    }
  },
  watch: {
    internetOnline(val) {
      if (val === false && this.intervalRunning === true) {
        this.clearInterval()
      } else if (val === true && this.intervalRunning === false) {
        this.setupInterval(this.pingTimeout)
      }
    }
  },
  computed: {
    serverOnline() {
      return this.$store.getters.serverOnline
    },
    internetOnline() {
      return this.$store.getters.internetConnection
    },
    lastRequestMadeAt() {
      return this.$store.getters.lastRequestMadeAt
    },
    pingTimeout() {
      return this.$store.getters.pingInterval
    }
  },
  methods: {
    intervalCallback() {
      const now = Date.now() // DO NOT FORGET
      const sinceLastRequest = now - this.lastRequestMadeAt
      if (this.lastRequestMadeAt !== 0 && (sinceLastRequest > this.pingTimeout * 1000) && this.internetOnline) {
        this.server.ping().then(
          (success) => {
            this.$store.dispatch('setConnectivity', {server: true})
          },
          (error) => {
            console.error(error)
            this.$store.dispatch('setConnectivity', {server: false})
          }
        )
      }
    },
    setupInterval(timeout) {
      timeout *= 1000
      if (!this.intervalRunning) {
        this.intervalCallback()
        this.interval = setInterval(this.intervalCallback, (timeout / 2) + 2000)
        this.intervalRunning = true
      }
    },
    clearInterval() {
      if (this.intervalRunning) {
        clearInterval(this.interval)
        this.intervalRunning = false
      }
    }
  },
  mounted() {
    this.server = SystemService.build(this.$vs)
    this.setupInterval(this.pingTimeout)
  },
  beforeDestroy() {
    this.clearInterval()
  }
}

</script>
<style lang="scss" scoped>
  span.feather-icon.overlay {
    position: absolute !important;
    right: -0.35rem !important;
    bottom: 0.2rem !important;
    z-index: 10 !important;
  }
  span.feather-icon.overlay > svg {
    width: 0.75rem;
    height: 0.75rem;
  }
  .stroke-3 {
    stroke-width: 3;
  }
</style>
