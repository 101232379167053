var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parentx" },
    [
      _c(
        "vs-sidebar",
        {
          directives: [
            {
              name: "hammer",
              rawName: "v-hammer:swipe",
              value: _vm.onMenuSwipe,
              expression: "onMenuSwipe",
              arg: "swipe",
            },
          ],
          ref: "verticalNavMenu",
          staticClass: "v-nav-menu items-no-padding",
          attrs: {
            "default-index": "-1",
            "click-not-close": _vm.clickNotClose,
            "reduce-not-rebound": _vm.reduceNotRebound,
            parent: _vm.parent,
            hiddenBackground: _vm.clickNotClose,
            reduce: _vm.reduce,
          },
          model: {
            value: _vm.isVerticalNavMenuActive,
            callback: function ($$v) {
              _vm.isVerticalNavMenuActive = $$v
            },
            expression: "isVerticalNavMenuActive",
          },
        },
        [
          _c(
            "div",
            { on: { mouseenter: _vm.mouseEnter, mouseleave: _vm.mouseLeave } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header-sidebar flex items-center justify-between",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "vx-logo cursor-pointer flex items-center",
                      attrs: { tag: "div", to: "/" },
                    },
                    [
                      _vm.title
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isMouseEnter || !_vm.reduce,
                                  expression: "isMouseEnter || !reduce",
                                },
                              ],
                              key:
                                _vm.$route.fullPath + "|" + _vm.institutionKey,
                              staticClass:
                                "vx-logo-text text-primary flex content-center flex-wrap",
                            },
                            [_c("logo", { attrs: { reduce: _vm.reduce } })],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _vm.showCloseButton
                        ? [
                            _c("feather-icon", {
                              staticClass: "m-0 cursor-pointer",
                              attrs: { icon: "XIcon" },
                              on: {
                                click: function ($event) {
                                  return _vm.$store.commit(
                                    "TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE",
                                    false
                                  )
                                },
                              },
                            }),
                          ]
                        : !_vm.showCloseButton && !_vm.verticalNavMenuItemsMin
                        ? [
                            _c("feather-icon", {
                              staticClass:
                                "mr-0 cursor-pointer flex content-center flex-wrap",
                              attrs: {
                                id: "btnVNavMenuMinToggler",
                                icon: _vm.reduce ? "CircleIcon" : "DiscIcon",
                                "svg-classes": "stroke-current text-primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleReduce(!_vm.reduce)
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showShadowBottom,
                    expression: "showShadowBottom",
                  },
                ],
                staticClass: "shadow-bottom",
              }),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.menuKey,
                  ref: "verticalNavMenuPs",
                  tag: "component",
                  staticClass: "scroll-area-v-nav-menu pt-2",
                  attrs: { settings: _vm.settings },
                  on: {
                    "ps-scroll-y": _vm.psSectionScroll,
                    scroll: _vm.psSectionScroll,
                  },
                },
                [
                  _vm._l(_vm.menuItemsUpdated, function (item, index) {
                    return [
                      item.header && !_vm.verticalNavMenuItemsMin
                        ? _c(
                            "span",
                            {
                              key: "header-" + index,
                              staticClass: "navigation-header truncate",
                            },
                            [_vm._v(_vm._s(_vm.$t(item.i18n) || item.header))]
                          )
                        : !item.header
                        ? [
                            !item.submenu
                              ? _c(
                                  "v-nav-menu-item",
                                  {
                                    key: "item-" + index,
                                    attrs: {
                                      index: index,
                                      to:
                                        item.slug !== "external"
                                          ? item.url
                                          : null,
                                      href:
                                        item.slug === "external"
                                          ? item.url
                                          : null,
                                      icon: item.icon,
                                      target: item.target,
                                      isDisabled: item.isDisabled,
                                      slug: item.slug,
                                      rule: item.rule,
                                      unlessRule: item.unlessRule,
                                      iconPack: item.iconPack,
                                      featherIcon: item.featherIcon,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.verticalNavMenuItemsMin,
                                            expression:
                                              "!verticalNavMenuItemsMin",
                                          },
                                        ],
                                        staticClass: "truncate",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t(item.i18n) || item.name)
                                        ),
                                      ]
                                    ),
                                    item.tag &&
                                    (_vm.isMouseEnter || !_vm.reduce)
                                      ? _c(
                                          "vs-chip",
                                          {
                                            staticClass: "ml-auto",
                                            attrs: { color: item.tagColor },
                                          },
                                          [_vm._v(_vm._s(item.tag))]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : [
                                  _c("v-nav-menu-group", {
                                    key: "group-" + index,
                                    attrs: {
                                      openHover: _vm.openGroupHover,
                                      group: item,
                                      groupIndex: index,
                                      open: _vm.isGroupActive(item),
                                      rule: item.rule,
                                      unlessRule: item.unlessRule,
                                    },
                                  }),
                                ],
                          ]
                        : _vm._e(),
                    ]
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "absolute inset-x-0 text-center",
                      staticStyle: { "font-size": "10px" },
                    },
                    [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.showVersion()
                            },
                          },
                        },
                        [
                          _vm._v(_vm._s(_vm.$t("versao")) + " "),
                          _c("b", [
                            _vm._v(_vm._s(this.$store.getters.appVersion)),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      !_vm.isVerticalNavMenuActive
        ? _c("div", {
            directives: [
              {
                name: "hammer",
                rawName: "v-hammer:swipe",
                value: _vm.onSwipeAreaSwipe,
                expression: "onSwipeAreaSwipe",
                arg: "swipe",
              },
            ],
            staticClass: "v-nav-menu-swipe-area",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }