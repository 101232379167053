var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.serverOnline || !_vm.internetOnline
    ? _c(
        "vs-dropdown",
        {
          staticClass: "cursor-pointer flex",
          attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
        },
        [
          !_vm.internetOnline
            ? _c(
                "div",
                { staticClass: "flex mr-2 items-center p-1 border rounded-lg" },
                [
                  _c(
                    "div",
                    { staticClass: "relative" },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "GlobeIcon", svgClasses: "h-6 w-6" },
                      }),
                      _c("feather-icon", {
                        staticClass:
                          "overlay bg-danger border border-warning rounded-full",
                        attrs: {
                          icon: "XIcon",
                          svgClasses: "text-warning stroke-3",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          !_vm.serverOnline
            ? _c(
                "div",
                { staticClass: "flex mr-2 items-center p-1 border rounded-lg" },
                [
                  _c(
                    "div",
                    { staticClass: "relative" },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "ServerIcon", svgClasses: "h-6 w-6" },
                      }),
                      _c("feather-icon", {
                        staticClass:
                          "overlay bg-danger border border-warning rounded-full",
                        attrs: {
                          icon: "XIcon",
                          svgClasses: "text-warning stroke-3",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "vs-dropdown-menu",
            { staticClass: "notification-dropdown vx-navbar-dropdown" },
            [
              _c("ul", { staticClass: "py-2 px-4" }, [
                !_vm.internetOnline
                  ? _c("li", { staticClass: "flex items-center" }, [
                      _c(
                        "div",
                        { staticClass: "relative" },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "GlobeIcon", svgClasses: "h-6 w-6" },
                          }),
                          _c("feather-icon", {
                            staticClass:
                              "overlay bg-danger border border-warning rounded-full",
                            attrs: {
                              icon: "XIcon",
                              svgClasses: "text-warning stroke-3",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "pl-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("voce-esta-sem-conexao-com-a-internet")
                            )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.serverOnline
                  ? _c("li", { staticClass: "flex items-center" }, [
                      _c(
                        "div",
                        { staticClass: "relative" },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "ServerIcon",
                              svgClasses: "h-6 w-6",
                            },
                          }),
                          _c("feather-icon", {
                            staticClass:
                              "overlay bg-danger border border-warning rounded-full",
                            attrs: {
                              icon: "XIcon",
                              svgClasses: "text-warning stroke-3",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "pl-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "verifique-sua-conexao-pois-nao-foi-possivel-conectar-com-o-servidor"
                              )
                            )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }